<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <AError
      v-if="tokenCheckApi.isError"
      :api="tokenCheckApi"
      :callbackReset="() => (tokenCheckApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Reset Password</v-card-title>
      <v-card-text class="text-center">You're resetting password of <b>{{mobile}}</b>.</v-card-text>
      <div class="d-flex align-center justify-space-around">
        <div v-if="!tokenCheckApi.isLoading" class="form-content text-center">
          <v-form v-if="!isError" ref="form" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <div class="text-center pt-1">
                <TextField
                  :value="newPassword"
                  label="New Password"
                  :rules="newPasswordRules"
                  type="password"
                  @input="newPassword = $event"
                ></TextField>
              </div>
              <div class="text-center pt-1">
                <TextField
                  :value="confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  type="password"
                  @input="confirmPassword = $event"
                ></TextField>
              </div>
              </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="tokenCheckApi.isLoading"
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
          <div v-if="isError">
            <div>
            <v-icon size="150"> mdi-information-slab-circle-outline </v-icon>
          </div>
          <div class="text-h5 pt-1 font-weight-black">Oops!</div>
          <div class="pt-2">This link has been broken or expired.</div>
          <div class="text-center pt-3">
            <v-btn width="75%" rounded color="primary" :to="{ name: 'PageMainHome' }" dark> Go to Home </v-btn>
          </div>
          </div>
        </div>
        <div v-else class="form-content text-center">
          <div class="text-center pt-2">
            <v-progress-circular
              :size="80"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import TextField from "@/components/Form/TextField.vue";
export default {
  components: {
    TextField
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    token: null,
    mobile: null,
    isError: false,
    newPassword: null,
    confirmPassword: null,
    confirmPasswordRules: [],
    newPasswordRules: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    tokenCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),

  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Reset Password",
      to: { name: "PageAccountPasswordReset" },
      exact: false,
    });

    this.api.url =
    this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/resetPassword";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageMainLogin" });
    };

    this.tokenCheckApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/token/check";

    this.tokenCheckApi.callbackReset = () => {
      this.tokenCheckApi.isLoading = true;
      this.tokenCheckApi.isError = false;
    };

    this.tokenCheckApi.callbackError = (e) => {
      this.tokenCheckApi.isLoading = false;
      this.tokenCheckApi.isError = true;
      this.tokenCheckApi.error = e;
      this.isError =  true
    };

    this.tokenCheckApi.callbackSuccess = (resp) => {
      this.mobile= resp.mobile
      this.tokenCheckApi.isLoading = false;

    };
  },
  watch: {
    newPassword: function () {
      this.newPasswordRules = [];
    },
    confirmPassword: function () {
      this.confirmPasswordRules = [];
    },
  },
  mounted() {
    if(this.$route.query){
      this.token = this.$route.query.t;
      this.checkToken()
    } else{
      this.$router.push({ name: "PageMainHome" });
    }
  },

  methods: {
    submit() {
      this.newPasswordRules = [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Min 8 characters",
      ];
      this.confirmPasswordRules = [
        (v) => !!v || "Password is required",
        this.passwordConfirmationRule,
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.form.validate()) {
          self.api.params = {
            password: self.newPassword,
            mobile: self.mobile
          };
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
    checkToken() {
        this.tokenCheckApi.params = {
          token: this.token,
        };
        this.$api.fetch(this.tokenCheckApi);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>